import React, { useEffect, useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";

import useGetData from "../../custom-hooks/useGetData";

import { db, auth } from "../../firebase.config";

import "../../styles/profile/orders.css";

const MyOrders = () => {
  const location = useLocation();
  const { data: usersData, loading } = useGetData("users");

  const [currentUserUID, setCurrentUserUID] = useState(null);

  // Retrieve the UID of the current logged-in user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserUID(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  // Get the current user's data
  const currentUserData = usersData?.find(
    (user) => user.uid === currentUserUID
  );

  return (
    <Helmet title="My Orders">
      <section>
        <Container>
          <Row>
            <Col lg="9">
              {location.pathname === "/profile/my-orders" && (
                <>
                  <h3 className="orders-content__title">MY ORDERS</h3>
                  {loading ? (
                    <p>Loading...</p> // Show a loading state while data is being fetched
                  ) : currentUserData?.orders ? (
                    <table className="table bordered">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Title</th>
                          <th>Price</th>
                          <th>Qty</th>
                          <th>Order</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentUserData?.orders.map((order) =>
                          order.products.map((product) => (
                            <tr key={product.orderId}>
                              <td>
                                <img src={product.imgUrl} alt="" />
                              </td>
                              <td>
                                {product.productName}
                                <p key={order.orderId}>{order.timestamp}</p>
                              </td>
                              <td>{product.price}</td>
                              <td>{product.quantity}</td>
                              <td>Placed</td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  ) : (
                    <p>No orders found.</p> // Show a message if reviews are not available
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default MyOrders;
