import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Routers from "../../routers/Routers";

// import CookieConsent from "../../pages/CookieConsent";
// import { useCookies } from "react-cookie";

import AdminNav from "../../admin/AdminNav";
import { Navigate, useLocation } from "react-router-dom";
import SideBar from "../../pages/profile/SideBar";

import "../Layout/layout.css";

import { useSelector } from "react-redux";

const Layout = () => {
  const location = useLocation();

  const showSidebar = location.pathname.startsWith("/profile");
  const { userRole } = useSelector((state) => state.user);

  if (location.pathname.startsWith("/dashboard") && userRole !== "admin") {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header />
      {userRole === "admin" && location.pathname.startsWith("/dashboard") && (
        <AdminNav />
      )}

      <div className="content-wrapper">
        {showSidebar && <SideBar />}
        <div className={`main-content ${showSidebar ? "with-sidebar" : ""}`}>
          <Routers />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Layout;
