// import necessary libraries and functions
import { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
} from "firebase/firestore";

// define custom hook useGetData with collectionName and category as inputs
const useGetData = (collectionName, category, sort) => {
  // define state variables for data and loading
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  // create collection reference with collectionName
  const collectionRef = collection(db, collectionName);

  // useEffect to get data from Firebase
  useEffect(() => {
    // define async function getData to get data from Firebase
    const getData = async () => {
      // create query based on category
      let q;
      if (category) {
        q = query(collectionRef, where("category", "==", category));
      } else {
        q = collectionRef;
      }

      // subscribe to realtime data updates
      await onSnapshot(q, (snapshot) => {
        // update state variables data and loading
        setData(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        setLoading(false);
      });
    };

    // call getData function to get data from Firebase
    getData();
  }, [category]);

  // define state variable searchQuery for search input
  const [searchQuery, setSearchQuery] = useState("");

  // define function handleSearch to update searchQuery state
  
  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // define filteredData variable based on searchQuery and data state
  const filteredData = searchQuery
    ? data.filter((item) =>
        item.productName.toLowerCase().includes(searchQuery)
      )
    : data;

  // Define sorting state and function
  const [sortOrder, setSortOrder] = useState("asc");

  const sortData = (key) => {
    const sortedData = [...data];
    sortedData.sort((a, b) => {
      if (sortOrder === "asc") {
        return a[key] - b[key];
      } else {
        return b[key] - a[key];
      }
    });

    setData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // return filtered data, loading, and handleSearch function
  return { data: filteredData, loading, handleSearch };
};

// export useGetData hook
export default useGetData;
