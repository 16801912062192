import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "../../styles/profile/sidebar.css";

const SideBar = () => {
  const sideBarItems = [
    {
      heading: "PROFILE",
      items: [
        {
          display: "Information",
          path: "/profile/information",
          icon: <i className="ri-user-follow-fill"></i>,
        },
        {
          display: "Address Book",
          path: "/profile/address-book",
          icon: <i className="ri-contacts-book-2-fill"></i>,
        },
      ],
    },
    {
      heading: "ORDERS",
      items: [
        {
          display: "My Orders",
          path: "/profile/my-orders",
          icon: <i className="ri-shopping-bag-line"></i>,
        },
        {
          display: "My Reviews",
          path: "/profile/my-reviews",
          icon: <i className="ri-star-fill"></i>,
        },
      ],
    },
    {
      heading: "SECURITY",
      items: [
        {
          display: "Change Password",
          path: "/profile/change-password",
          icon: <i className="ri-lock-password-line"></i>,
        },
      ],
    },
  ];

  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const sidebarRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setSidebarVisible(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleLinkClick = () => {
    if (window.innerWidth < 768) {
      setSidebarVisible(false);
    }
  };

  return (
    <div className="sidebar">
      {!isSidebarVisible && (
        <div className="profile" onClick={toggleSidebar}>
          <div className="profile__toggle">
            <i className="ri-menu-unfold-fill"></i>
          </div>
        </div>
      )}
      <div
        ref={sidebarRef}
        className={`sidebar-container ${
          isSidebarVisible ? "sidebar-visible" : ""
        }`}
      >
        <div className="profile-sidebar">
          {sideBarItems.map((item, index) => (
            <div key={index}>
              <h4>{item.heading}</h4>
              <ul>
                {item.items.map((subItem, subIndex) => (
                  <li className="profile-sidebar__link" key={subIndex}>
                    <NavLink
                      to={subItem.path}
                      activeClassName="active__admin-menu"
                      exact
                      onClick={handleLinkClick}
                    >
                      <span className="sub-item-icon">{subItem.icon}</span>
                      <span className="sub-item-display">
                        {subItem.display}
                      </span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SideBar;
