import React, { useEffect, useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";

import useGetData from "../../custom-hooks/useGetData";

import { db, auth } from "../../firebase.config";

import "../../styles/profile/orders.css";

const MyReviews = () => {
  const location = useLocation();
  const { data: usersData, loading } = useGetData("users");

  const [currentUserUID, setCurrentUserUID] = useState(null);

  // Retrieve the UID of the current logged-in user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserUID(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  // Get the current user's data
  const currentUserData = usersData?.find(
    (user) => user.uid === currentUserUID
  );

  return (
    <Helmet title="My Reviews">
      <section>
        <Container>
          <Row>
            <Col lg="9">
              {location.pathname === "/profile/my-reviews" && (
                <>
                  <h3 className="orders-content__title">MY REVIEWS</h3>

                  {loading ? (
                    <p>Loading...</p> // Show a loading state while data is being fetched
                  ) : currentUserData?.reviews ? (
                    <table className="table bordered">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Rating</th>
                          <th>Review</th>
                          <th>Username</th>
                          <th>Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {currentUserData.reviews.map((review) => (
                          <tr key={review.productId}>
                            <td>
                              {review.image && (
                                <img src={review.image.imgUrl} alt="" />
                              )}
                            </td>
                            <td>{review.productName}</td>
                            <td>{review.rating}</td>
                            <td>{review.text}</td>
                            <td>{review.userName}</td>
                            <td>{review.timestamp}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <p>No reviews found.</p> // Show a message if reviews are not available
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default MyReviews;
