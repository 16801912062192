import React, { useEffect, useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase.config";
import { toast } from "react-toastify";

import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase.config";

import { useDispatch } from "react-redux";
import { setUserId } from "../redux/slices/userReducer";

import "../styles/login.css";

import { Spinner } from "reactstrap";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Track whether to show or hide the password
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const rememberEmail = localStorage.getItem("rememberEmail");
    const rememberPassword = localStorage.getItem("rememberPassword");

    if (rememberEmail) {
      setEmail(rememberEmail);
      setRememberMe(true);
    }
    if (rememberPassword) {
      setPassword(rememberPassword);
    }
  }, []);

  const signIn = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      // Assuming you have obtained the userId from the login response
      const userId = user.uid;
      const displayName = user.displayName;

      // Retrieve the user's role from Firestore or Realtime Database based on the userId
      // For simplicity, let's assume you have a "users" collection where each document represents a user and contains a "role" field
      const userRef = doc(db, "users", userId);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const userRole = userSnapshot.data().userRole;

        if (rememberMe) {
          localStorage.setItem("rememberEmail", email);
          localStorage.setItem("rememberPassword", password);
        } else {
          localStorage.removeItem("rememberEmail");
          localStorage.removeItem("rememberPassword");
        }

        dispatch(setUserId({ userId, displayName, userRole }));

        setLoading(false);
        toast.success("Successfully logged in");
        navigate("/cart");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Wrong Email or password!");
    }
  };

  return (
    <Helmet title="Login">
      <section>
        <Container>
          <Row>
            <Col lg="6" className="m-auto text-center">
              <h3 className="fw-bold mb-4">Login</h3>
              <Form className="auth__form" onSubmit={signIn}>
                <FormGroup className="form__group">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? (
                        <i class="ri-eye-off-line"></i>
                      ) : (
                        <i class="ri-eye-line"></i>
                      )}
                    </button>
                  </div>
                </FormGroup>
                <FormGroup className="remember__me">
                  <label htmlFor="rememberMe">
                    <input
                      type="checkbox"
                      id="rememberMe"
                      checked={rememberMe}
                      onChange={() => setRememberMe(!rememberMe)}
                    />
                    Remember Me
                  </label>
                </FormGroup>

                <FormGroup className="form__group">
                  <p className="forgot-password mt-0">
                    <Link to="/forgot-password">Forgot Password?</Link>
                  </p>
                </FormGroup>

                {loading ? (
                  <Spinner color="primary" />
                ) : (
                  <button type="submit" className="buy__btn auth__btn mt-3">
                    Login
                  </button>
                )}
                <p>
                  Don't have an account?{" "}
                  <Link to="/signup">Create an account</Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Login;
