import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userReducer";

import cartSlice from "./slices/cartSlice";

const store = configureStore({
  reducer: {
    cart: cartSlice,
    user: userReducer,
  },
});

export default store;
