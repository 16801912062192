import React, { useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { setDoc, doc } from "firebase/firestore";

import { auth } from "../firebase.config";
import { storage } from "../firebase.config";
import { db } from "../firebase.config";

import { toast } from "react-toastify";

import "../styles/login.css";
import "../styles/signup.css";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); // Track whether to show or hide the password

  const navigate = useNavigate();

  // Helper function to resize the image
  const resizeImage = (image, maxWidth, maxHeight) => {
    const canvas = document.createElement("canvas");
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }
    } else {
      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }
    }

    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, width, height);

    return canvas.toDataURL("image/jpeg", 0.8); // Convert canvas to base64 JPEG with quality 0.8
  };

  const signup = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const user = userCredential.user;

      const fileReader = new FileReader();
      fileReader.onload = async (e) => {
        const image = new Image();
        image.onload = async () => {
          const resizedDataURL = resizeImage(image, 800, 800);

          const storageRef = ref(storage, `images/${Date.now() + userName}`);
          const uploadTask = uploadBytesResumable(
            storageRef,
            dataURLtoBlob(resizedDataURL)
          );

          uploadTask.on(
            (error) => {
              toast.error(error.message);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then(async (downloadURL) => {
                  // Update user profile
                  await updateProfile(user, {
                    displayName: userName,
                    photoURL: downloadURL,
                  });

                  // Store user data in Firestore database
                  await setDoc(doc(db, "users", user.uid), {
                    uid: user.uid,
                    displayName: userName,
                    email,
                    photoURL: downloadURL,
                    userRole: "user",
                  });

                  setLoading(false);
                  toast.success("Account Created");

                  navigate("/shop");
                  window.location.reload(); // Reload the page
                })
                .catch((error) => {
                  console.error(error);
                });
            }
          );
        };

        image.src = e.target.result;
      };

      fileReader.readAsDataURL(file);
    } catch (error) {
      setLoading(false);
      toast.error("Please fill the SignUp Form");
    }
  };

  // Helper function to convert data URL to Blob
  const dataURLtoBlob = (dataURL) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  };

  // hide unhide password
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Helmet title="Signup">
      <section>
        <Container>
          <Row>
            <Col lg="6" className="m-auto text-center">
              <h3 className="fw-bold mb-4">Signup</h3>
              <Form className="auth__form" onSubmit={signup}>
                <FormGroup className="form__group">
                  <input
                    type="text"
                    placeholder="Username"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="form__group">
                  <div className="password-input">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? (
                        <i class="ri-eye-off-line"></i>
                      ) : (
                        <i class="ri-eye-line"></i>
                      )}
                    </button>
                  </div>
                </FormGroup>

                <FormGroup className="form__group ">
                  <div className="display_image">
                    Display Image
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <p className="mt-0">Image size less than 1MB</p>
                </FormGroup>

                {loading ? (
                  <Spinner color="primary" />
                ) : (
                  <button type="submit" className="buy__btn auth__btn mt-3">
                    Create an Account
                  </button>
                )}
                <p>
                  Already have an account? <Link to="/login">Login</Link>
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Signup;
