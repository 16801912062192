import React, { useRef, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./header.css";
import { motion } from "framer-motion";
import logo from "../../assets/images/logo1.png";
import userIcon from "../../assets/images/user-icon.png";
import { Container, Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import useAuth from "../../custom-hooks/useAuth";
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth, db } from "../../firebase.config";
import { toast } from "react-toastify";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

//icons
import user from "../../assets/images/user.png";
import wish from "../../assets/images/heart.png";
import order from "../../assets/images/shopping-cart.png";
import inbox from "../../assets/images/envelope.png";
import logOut from "../../assets/images/log-out.png";
import addUser from "../../assets/images/add-user.png";
import login from "../../assets/images/login.png";

const nav__links = [
  {
    path: "home",
    display: "Home",
  },
  {
    path: "shop",
    display: "Shop",
  },
  {
    path: "cart",
    display: "Cart",
  },
];

const userNav__links = [
  {
    path: "home",
    display: "Home",
  },
  {
    path: "shop",
    display: "Shop",
  },
  {
    path: "cart",
    display: "Cart",
  },
  {
    path: "dashboard",
    display: "Dashboard",
  },
];

const second__navlink = [
  {
    path: "shop/bedroom-mattress",
    display: "Bedroom Mattresses",
    category: "bed",
  },
  {
    path: "shop/lounges-sofabed",
    display: "Lounges Sofa Bed",
    category: "sofa",
  },
  {
    path: "shop/dining-barstool",
    display: "Dining Set Bar Stools",
  },
];

const Header = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(false);

  const headerRef = useRef(null);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  let profileActionRef = useRef();
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const dispatch = useDispatch(); // Add dispatch from react-redux

  const stickyHeaderFunc = () => {
    window.addEventListener("scroll", () => {
      if (
        document.body.scrollTop > 80 ||
        document.documentElement.scrollTop > 80
      ) {
        headerRef.current.classList.add("sticky__header");
      } else {
        headerRef.current.classList.remove("sticky__header");
      }
    });
  };
  const [currentUserData, setCurrentUserData] = useState(null);

  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        // Retrieve the updated user data from the database
        const userDocRef = doc(db, "users", user.uid);
        getDoc(userDocRef)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              setCurrentUserData(userData);
            }
          })
          .catch((error) => {
            console.error("Error retrieving user data:", error);
          });
      } else {
        // User is signed out
        setCurrentUserData(null);
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  //profile drop down use effect
  useEffect(() => {
    let handler = (e) => {
      if (!profileActionRef.current.contains(e.target)) {
        setOpenProfile(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const navigateTo = (path) => {
    setOpenProfile(false);
    window.location.href = path; // Navigate to the specified path
  };

  const logout = () => {
    // Clear local storage
    localStorage.clear();

    signOut(auth)
      .then(() => {
        toast.success("Logged out");
        navigate("/home");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  //sticky header
  useEffect(() => {
    stickyHeaderFunc();
    return () => window.removeEventListener("scroll", stickyHeaderFunc);
  }, []);

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  const navigateToCart = () => {
    if (isCartEmpty) {
      toast.error("Please add items to view the cart.");
    } else {
      navigate("/cart");
    }
  };

  const totalItems = cartItems
    ? cartItems.reduce((sum, item) => {
        const itemQuantity = parseFloat(item.quantity);
        return sum + itemQuantity;
      }, 0)
    : 0;

  useEffect(() => {
    if (cartItems && cartItems.length === 0) {
      setIsCartEmpty(true);
    } else {
      setIsCartEmpty(false);
    }
  }, [cartItems]);

  //navigation links for user and admin
  const isAdmin = currentUserData?.userRole === "admin";

  const navigationLinks = isAdmin ? userNav__links : nav__links;

  return (
    <>
      <header className="header" ref={headerRef}>
        <Container className="my-0">
          <Row>
            <div className="nav__wrapper">
              <div className="logo">
                <Link to="/home" className="link__logo">
                  <img src={logo} alt="logo" />
                  <div className="logo-text">
                    <h1 className="star">ST&#9733;R</h1>
                    <p className="furniture">Furniture</p>
                  </div>
                </Link>
              </div>

              {/* <div className="navigation" ref={menuRef} onClick={menuToggle}>
            <ul className="menu">
              {nav__links.map((item, index) => (
                <li className="nav__item" key={index}>
                  {item.path === "cart" ? (
                    <a
                      href="#"
                      className={(navClass) =>
                        navClass.isActive ? "nav__active" : ""
                      }
                      onClick={navigateToCart}
                    >
                      {item.display}
                    </a>
                  ) : (
                    <NavLink
                      to={item.path}
                      className={(navClass) =>
                        navClass.isActive ? "nav__active" : ""
                      }
                    >
                      {item.display}
                    </NavLink>
                  )}
                </li>
              ))}
            </ul>
          </div> */}
              <div className="navigation" ref={menuRef} onClick={menuToggle}>
                <ul className="menu">
                  {navigationLinks.map((item, index) => (
                    <li className="nav__item" key={index}>
                      <NavLink
                        to={item.path}
                        className={(navClass) =>
                          navClass.isActive ? "nav__active" : ""
                        }
                      >
                        {item.display}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="nav__icons">
                <span className="cart__icon" onClick={navigateToCart}>
                  <i className="ri-shopping-cart-line"></i>
                  <span className="badge">{totalItems}</span>
                </span>

                <div className="profile-container" ref={profileActionRef}>
                  <div
                    className="profile-trigger"
                    onClick={() => {
                      setOpenProfile(!openProfile);
                    }}
                  >
                    <motion.img
                      whileTap={{ scale: 1.2 }}
                      src={
                        currentUserData && currentUserData.photoURL
                          ? currentUserData.photoURL
                          : userIcon
                      }
                      alt=""
                    />
                  </div>
                  <div
                    className={`dropdown-menu ${
                      openProfile ? "active" : "inactive"
                    }`}
                  >
                    {currentUserData ? (
                      <>
                        <h3 className="text-center">
                          {currentUserData.displayName} <br />{" "}
                          <span>{currentUserData.email}</span>
                        </h3>
                        <ul>
                          <DropdownItem
                            img={user}
                            text={"My Profile"}
                            onClick={() => navigateTo("/profile/information")}
                          />

                          <DropdownItem
                            img={order}
                            text={"Orders"}
                            onClick={() => navigateTo("/profile/orders")}
                          />

                          <DropdownItem
                            img={logOut}
                            text={"Logout"}
                            onClick={() => {
                              logout();
                              navigateTo("/home");
                            }}
                          />
                        </ul>
                      </>
                    ) : (
                      <>
                        <h3 className="text-center">Welcome!</h3>
                        <ul>
                          <DropdownItem
                            img={addUser}
                            text={"Signup"}
                            onClick={() => navigateTo("/signup")}
                          />
                          <DropdownItem
                            img={login}
                            text={"Login"}
                            onClick={() => navigateTo("/login")}
                          />
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <div className="bed__menu">
                  <span onClick={menuToggle}>
                    <i className="ri-menu-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </header>
      <Row className="w-100 m-0 second__nav">
        {/* Secondary Navigation Links */}
        <div className="second__navigation">
          <ul className="second__menu">
            {second__navlink.map((item, index) => (
              <li key={index} className="second-nav__item">
                <NavLink
                  to={item.path}
                  className={(navClass) =>
                    navClass.isActive ? "nav__active" : ""
                  }
                >
                  {item.display}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </Row>
    </>
  );
};

function DropdownItem(props) {
  return (
    <li className="dropdownItem" onClick={props.onClick}>
      <img src={props.img} alt=""></img>
      <Link to={props.to || "#"}>{props.text}</Link>
    </li>
  );
}

export default Header;
