import React, { useState } from "react";

import { Form, FormGroup, Container, Row, Col } from "reactstrap";
import { toast } from "react-toastify";

import { db, storage } from "../firebase.config";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const AddProduct = () => {
  const [enterTitle, setEnterTitle] = useState("");
  const [enterShortDesc, setEnterShortDesc] = useState("");
  const [enterDescription, setEnterDescription] = useState("");
  const [enterCategory, setEnterCategory] = useState("");
  const [enterSection, setEnterSection] = useState("");
  const [enterPrice, setEnterPrice] = useState("");
  const [enterProductImg, setEnterProductImg] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // Track upload progress

  const navigate = useNavigate();

  const addProduct = async (e) => {
    e.preventDefault();
    setLoading(true);

    //===== add product to the Firebase database =====

    try {
      const docRef = await collection(db, "products");

      // Iterate over each image file and upload to Firebase Storage
      const uploadTasks = enterProductImg.map((imageFile) => {
        const storageRef = ref(
          storage,
          `productImages/${Date.now() + imageFile.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, imageFile);

        uploadTask.on("state_changed", (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        });

        return uploadTask;
      });

      const uploadPromises = uploadTasks.map(
        (uploadTask) =>
          new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setUploadProgress(progress);
              },
              (error) => {
                reject(error);
              },
              () => {
                resolve();
              }
            );
          })
      );

      await Promise.all(uploadPromises);

      // // Wait for all the upload tasks to complete
      // await Promise.all(uploadTasks.map((uploadTask) => uploadTask));

      // Get download URLs for all the uploaded images
      const downloadURLs = await Promise.all(
        uploadTasks.map((uploadTask) => getDownloadURL(uploadTask.snapshot.ref))
      );

      // Create an array of image URLs
      const imageUrls = downloadURLs.map((downloadURL) => ({
        imgUrl: downloadURL,
      }));

      // Add the product details to the database
      await addDoc(docRef, {
        productName: enterTitle,
        shortDesc: enterShortDesc,
        description: enterDescription,
        category: enterCategory,
        price: enterPrice,
        images: imageUrls,
        section: enterSection,
        reviews: [],
      });

      setLoading(false);
      toast.success("Product successfully added");
      navigate("/dashboard/all-products");
    } catch (err) {
      setLoading(false);
      toast.error("Product not added");
    }
  };

  return (
    <section className="pt-3">
      <Container>
        <Row>
          <Col lg="12">
            {uploadProgress < 100 ? (
              <>
                <h4 className="mb-5 fw-bold">Add Product</h4>
                <Form onSubmit={addProduct}>
                  <FormGroup className="form__group">
                    <span>Product title</span>
                    <input
                      type="text"
                      placeholder="Double sofa"
                      value={enterTitle}
                      onChange={(e) => setEnterTitle(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup className="form__group">
                    <span>Short Description</span>
                    <textarea
                      type="text"
                      placeholder="lorem.....a"
                      value={enterShortDesc}
                      onChange={(e) => setEnterShortDesc(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup className="form__group">
                    <span>Description</span>
                    <textarea
                      type="text"
                      placeholder="Description...."
                      value={enterDescription}
                      onChange={(e) => setEnterDescription(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <div className="d-flex align-items-center justify-content-between gap-5">
                    <FormGroup className="form__group w-50">
                      <span>Price</span>
                      <input
                        type="number"
                        placeholder="$100"
                        value={enterPrice}
                        onChange={(e) => setEnterPrice(e.target.value)}
                        required
                      />
                    </FormGroup>

                    <FormGroup className="form__group w-50">
                      <span>Category</span>
                      <select
                        className="w-100 p-2"
                        value={enterCategory}
                        onChange={(e) => setEnterCategory(e.target.value)}
                        required
                      >
                        <option>Select Category</option>
                        <option value="dining">Dining Room</option>
                        <option value="sofa">Sofa</option>
                        <option value="bed">Bed</option>
                        <option value="mattress">Mattress</option>
                        <option value="coffee_table">Coffee Table</option>
                        <option value="carpet">Carpet</option>
                        <option value="pillows">Pillows</option>
                        <option value="stool">Bar Stool</option>
                        <option value="sofa_bed">Sofa Bed</option>
                      </select>
                    </FormGroup>

                    <FormGroup className="form__group w-50">
                      <span>Section</span>
                      <select
                        className="w-100 p-2"
                        value={enterSection}
                        onChange={(e) => setEnterSection(e.target.value)}
                      >
                        <option>Select Section</option>
                        <option value="trending">Trending Products</option>
                        <option value="best_sales">Best Sales</option>
                        <option value="new">New Arrivals</option>
                        <option value="popular">Popular Category</option>
                      </select>
                    </FormGroup>
                  </div>

                  <div>
                    <FormGroup className="form__group">
                      <span>Product Image</span>
                      <input
                        type="file"
                        multiple
                        onChange={(e) =>
                          setEnterProductImg(Array.from(e.target.files))
                        }
                        required
                      />
                    </FormGroup>
                  </div>

                  {/* Upload progress */}
                  <div className="progress mt-4">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${uploadProgress}%` }}
                      aria-valuenow={uploadProgress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {`${uploadProgress.toFixed(0)}%`}
                    </div>
                  </div>

                  <button type="submit" className="buy__btn">
                    Add Product
                  </button>
                </Form>
              </>
            ) : (
              <>
                <h4 className="py-5">Uploading</h4>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AddProduct;
