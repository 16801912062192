import React from "react";
import "../styles/NotFoundPage.css"; // Import the CSS file for styling
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="not-found-container">
      <div className="not-found-icon">
        <i class="ri-error-warning-line"></i>
      </div>
      <h1 className="not-found-title">404 - Page Not Found</h1>
      <p className="not-found-message">
        The page you are looking for does not exist.
      </p>

      <Link to="/home" className="btn btn-danger">
        Go back to Home Page
      </Link>
    </div>
  );
};

export default NotFoundPage;
