import React, { useMemo, useState, useEffect } from "react";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import "../styles/shop.css";
import ProductsList from "../components/UI/ProductsList";
import useGetData from "../custom-hooks/useGetData";
import { Link } from "react-router-dom";

const BedroomCategory = () => {
  const [category, setCategory] = useState("bed");
  const [bedroomProducts, setBedroomProducts] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const { data: products, loading, handleSearch } = useGetData("products");

  const handleFilter = (event) => {
    setCategory(event.target.value);
  };

  const sortedProducts = useMemo(() => {
    if (sortBy === "price-asc") {
      return [...bedroomProducts].sort((a, b) => a.price - b.price);
    } else if (sortBy === "price-desc") {
      return [...bedroomProducts].sort((a, b) => b.price - a.price);
    } else {
      return bedroomProducts;
    }
  }, [bedroomProducts, sortBy]);

  const handleSort = (event) => {
    setSortBy(event.target.value);
  };

  useEffect(() => {
    const filteredBedroomProducts = products.filter(
      (item) =>
        item.category === "bed" ||
        item.category === "mattress" ||
        item.category === "pillows"
    );

    setBedroomProducts(filteredBedroomProducts);
  }, [products]);

  return (
    <Helmet title="Bedroom Mattress">
      <CommonSection title="Bedroom Mattress" />
      <section className="section1">
        <Container>
          <Row>
            <Col lg="3" md="3">
              <div className="filter__widget">
                <button className="btn btn-primary fs-5">
                  {" "}
                  <Link to="/shop">Go to All Products</Link>
                </button>
              </div>
            </Col>
            <Col lg="3" md="9" className="text-end">
              <div className="filter__widget">
                <select onChange={handleSort}>
                  <option value="">Sort By</option>
                  <option value="price-asc">Low to High</option>
                  <option value="price-desc">High to Low</option>
                </select>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="search__box">
                <input
                  type="text"
                  placeholder="Search Products"
                  // value={searchQuery}
                  onChange={handleSearch}
                />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          <Row>
            {loading ? (
              <div className="product__item product__placeholder">
                {/* Placeholder content for loading effect */}
                <div className="product__img"></div>
                <div className="p-2 product__info">
                  <div className="product__name-placeholder"></div>
                  <div className="product__category-placeholder"></div>
                </div>
                <div className="product__card-buttom-placeholder"></div>
              </div>
            ) : products.length === 0 ? (
              <h1 className="text-center fs-4">No products are found</h1>
            ) : (
              <ProductsList data={sortedProducts} />
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default BedroomCategory;
