import React from "react";
import "./footer.css";
import star from "../../assets/images/star.png";

import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col lg="4" className="mb-4" md="6">
            <div className="logo_footer">
              <Link to="/home" className="link__logo">
                <img src={star} alt="" />
                <div className="logo-text-footer">
                  <img src="" />
                </div>
              </Link>
            </div>
            <p className="footer__text mt-4">
              Star Furniture: Premium Australian store for stylish and quality
              furniture for homes, offices, and commercial spaces. Contemporary,
              modern, and classic designs sourced from trusted manufacturers.
              Exceptional service and competitive prices.
            </p>
          </Col>

          <Col lg="3" md="3" className="mb-4">
            <div className="footer__quick-links">
              <h4 className="quick__links-title">Top Categories</h4>

              <ListGroup className="mb-3">
                <ListGroupItem className="ps-0 border-0">
                  <Link to="/shop">All Products</Link>
                </ListGroupItem>
                <ListGroupItem className="ps-0 border-0">
                  <Link to="/shop/bedroom-mattress">Bedroom Mattresses</Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0">
                  <Link to="/shop/lounges-sofabed">Lounges Sofa Bed</Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0">
                  <Link to="/shop/dining-barstool">Dining Set Bar Stools</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="2" md="3" className="mb-4">
            <div className="footer__quick-links">
              <h4 className="quick__links-title">Useful Links</h4>
              <ListGroup className="mb-3">
                <ListGroupItem className="ps-0 border-0">
                  <Link to="/shop">Shop</Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0">
                  <Link to="/cart">Cart</Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0">
                  <Link to="/profile/information">My Profile</Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0">
                  <Link to="/profile/my-orders">My Orders</Link>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="3" md="4">
            <div className="footer__quick-links">
              <h4 className="quick__links-title">Contact</h4>
              <ListGroup className="footer__contact">
                <ListGroupItem className="ps-0 border-0 d-flex align-item-center gap-2">
                  <span>
                    <i class="ri-map-pin-line"></i>
                  </span>
                  <Link
                    to="https://www.google.com/maps/place/Star+furniture/@-33.8549518,151.0352079,17z/data=!4m15!1m8!3m7!1s0x6b12bcafe505982b:0x257bb1694b4e05c6!2s1B+Queen+St,+Auburn+NSW+2144,+Australia!3b1!8m2!3d-33.8543557!4d151.0359462!16s%2Fg%2F11cpn2tf93!3m5!1s0x6b12bd7a9e20cfd9:0x4468a3e6db807dac!8m2!3d-33.8549518!4d151.0363645!16s%2Fg%2F11nrxy1_c2?entry=ttu"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    B2/1B QUEEN ST. AUBURN NSW 2144
                  </Link>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-item-center gap-2">
                  <span>
                    <i className="ri-phone-line"></i>
                  </span>
                  <a href="tel:0492164440" className="py-1">
                    0492164440
                  </a>
                </ListGroupItem>

                <ListGroupItem className="ps-0 border-0 d-flex align-item-center gap-2">
                  <span>
                    <i className="ri-mail-line"></i>
                  </span>
                  <a href="mailto:starfurniture1999@gmail.com" className="py-2">
                    starfurniture1999@gmail.com
                  </a>
                </ListGroupItem>
              </ListGroup>
            </div>
          </Col>

          <Col lg="12">
            <span className="footer__copyright">
              Copyright ©{year} Star Furniture. All rights reserved.
            </span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
