import { createSlice } from "@reduxjs/toolkit";

// Action type constants
const UPDATE_TOTAL_AMOUNT = "cart/UPDATE_TOTAL_AMOUNT";
const UPDATE_TOTAL_QUANTITY = "cart/UPDATE_TOTAL_QUANTITY";
const CLEAR_CART = "cart/CLEAR_CART";

// Action creators
export const updateTotalAmount = (amount) => ({
  type: UPDATE_TOTAL_AMOUNT,
  payload: amount,
});

export const updateTotalQuantity = (quantity) => ({
  type: UPDATE_TOTAL_AMOUNT,
  payload: quantity,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

// Retrieve the cart data from localStorage
let cartData;
try {
  cartData = JSON.parse(localStorage.getItem("cart"));
} catch (error) {
  cartData = [];
}

let initialTotalAmount = Number(localStorage.getItem("totalAmount"));
if (isNaN(initialTotalAmount)) {
  initialTotalAmount = 0;
}

let initialTotalQuantity = Number(localStorage.getItem("totalQuantity"));
if (isNaN(initialTotalQuantity)) {
  initialTotalQuantity = 0;
}

const initialState = {
  cartItems: cartData || [],
  totalAmount: initialTotalAmount,
  totalQuantity: initialTotalQuantity,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.cartItems.find(
        (item) => item.id === newItem.id
      );

      state.totalQuantity++;

      if (!existingItem) {
        state.cartItems.push({
          id: newItem.id,
          productName: newItem.productName,
          imgUrl: newItem.imgUrl,
          price: newItem.price,
          quantity: 1,
          totalPrice: newItem.price,
        });
      } else {
        existingItem.quantity++;
        existingItem.totalPrice =
          Number(existingItem.totalPrice) + Number(newItem.price);
      }

      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );

      // Save the cart items to localStorage
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    deleteItem: (state, action) => {
      const id = action.payload;
      const existingItem = state.cartItems.find((item) => item.id === id);
      if (existingItem) {
        state.cartItems = state.cartItems.filter((item) => item.id !== id);
        state.totalQuantity = state.totalQuantity - existingItem.quantity;
      }

      state.totalAmount = state.cartItems.reduce(
        (total, item) => total + Number(item.price) * Number(item.quantity),
        0
      );

      // Save the cart items to localStorage
      localStorage.setItem("cart", JSON.stringify(state.cartItems));
    },

    clearItem: (state) => {
      state.cartItems = [];
      state.totalQuantity = 0;
      state.totalAmount = 0;
      localStorage.removeItem("cart");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("totalQuantity");
    },

    setItems: (state, action) => {
      state.cartItems = action.payload;
    },
  },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;
