import React, { useMemo, useState } from "react";
import CommonSection from "../components/UI/CommonSection";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import "../styles/shop.css";
import ProductsList from "../components/UI/ProductsList";
import useGetData from "../custom-hooks/useGetData";

const Shop = () => {
  const [category, setCategory] = useState("");
  const [sortBy, setSortBy] = useState("");
  const {
    data: products,
    loading,
    handleSearch,
  } = useGetData("products", category);

  const handleFilter = (event) => {
    setCategory(event.target.value);
  };

  const sortedProducts = useMemo(() => {
    if (sortBy === "price-asc") {
      return [...products].sort((a, b) => a.price - b.price);
    } else if (sortBy === "price-desc") {
      return [...products].sort((a, b) => b.price - a.price);
    } else {
      return products;
    }
  }, [products, sortBy]);

  const handleSort = (event) => {
    setSortBy(event.target.value);
  };

  return (
    <Helmet title="Shop">
      <CommonSection title="Products" />
      <section className="section1">
        <Container>
          <Row>
            <Col lg="3" md="3">
              <div className="filter__widget">
                <select onChange={handleFilter}>
                  <option value="">Filter By Category</option>
                  <option value="dining">Dining Room</option>
                  <option value="sofa">Sofa</option>
                  <option value="bed">Bed</option>
                  <option value="mattress">Mattress</option>
                  <option value="coffee_table">Coffee Table</option>
                  <option value="carpet">Carpet</option>
                  <option value="pillows">Pillows</option>
                  <option value="stool">Bar Stool</option>
                  <option value="sofa_bed">Sofa Bed</option>
                </select>
              </div>
            </Col>
            <Col lg="3" md="9" className="text-end">
              <div className="filter__widget">
                <select onChange={handleSort}>
                  <option value="">Sort By</option>
                  <option value="price-asc">Low to High</option>
                  <option value="price-desc">High to Low</option>
                </select>
              </div>
            </Col>
            <Col lg="6" md="12">
              <div className="search__box">
                <input
                  type="text"
                  placeholder="Search Products"
                  // value={searchQuery}
                  onChange={handleSearch}
                />
                <span>
                  <i className="ri-search-line"></i>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>
          <Row>
            {loading ? (
              <div className="product__item product__placeholder">
                {/* Placeholder content for loading effect */}
                <div className="product__img"></div>
                <div className="p-2 product__info">
                  <div className="product__name-placeholder"></div>
                  <div className="product__category-placeholder"></div>
                </div>
                <div className="product__card-buttom-placeholder"></div>
              </div>
            ) : products.length === 0 ? (
              <h1 className="text-center fs-4">No products are found</h1>
            ) : (
              <ProductsList data={sortedProducts} />
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Shop;
