import React from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Container, Row, Col } from "reactstrap";
import { NavLink, useLocation } from "react-router-dom";

const Wishlist = () => {
  const location = useLocation();
  return (
    <Helmet title="Wishlist">
      <section>
        <Container>
          <Row>
            <Col lg="9">
              {location.pathname === "/profile/wishlist" && (
                <>
                  <h3 className="orders-content__title">WISHLIST</h3>
                  <hr />
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Wishlist;
