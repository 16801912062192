import React, { useState, useRef } from "react";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";

import "../styles/checkout.css";
import { useSelector, useDispatch } from "react-redux";
import { db } from "../firebase.config";
import { getAuth } from "firebase/auth";

import {
  collection,
  addDoc,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { cartActions } from "../redux/slices/cartSlice";
import { useNavigate } from "react-router-dom";
import { Spinner } from "reactstrap";
import emailjs from "emailjs-com";

const Checkout = () => {
  const totalQty = useSelector((state) => state.cart.totalQuantity);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef();
  const [success, setSuccess] = useState(null);

  //get the total items
  const totalItems = cartItems.reduce((sum, item) => {
    const itemQuantity = parseFloat(item.quantity);
    return sum + itemQuantity;
  }, 0);

  // for total price from cart items
  const totalPrice = cartItems.reduce((sum, item) => {
    const itemPrice = parseFloat(item.price);
    const itemQuantity = parseFloat(item.quantity);
    return sum + itemPrice * itemQuantity;
  }, 0);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
    postalCode: "",
    country: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    if (
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.streetAddress ||
      !formData.city ||
      !formData.postalCode ||
      !formData.country
    ) {
      toast.error("Please fill in all the required fields");
      return;
    }

    // Validate product cart
    if (cartItems.length === 0) {
      toast.error("Your cart is empty");
      return;
    }

    setLoading(true);

    //get the current user ID
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const userName = user ? user.displayName : null;

    // Proceed with placing the order
    const orderList = {
      name: formData.name,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      streetAddress: formData.streetAddress,
      city: formData.city,
      postalCode: formData.postalCode,
      country: formData.country,
      totalQty: totalQty,
      totalAmount: totalAmount,
      products: cartItems,
      userId: userId,
      username: userName,
      timestamp: new Date().toLocaleString(),
    };

    try {
      // Add order to the "orders" collection
      const ordersCollectionRef = collection(db, "orders");
      const docRef = await addDoc(ordersCollectionRef, { orderList });
      const orderId = docRef.id; // Get the generated order ID

      // Add order to the user's collection
      if (userId) {
        const userDocRef = doc(db, "users", userId);
        // ...
        await updateDoc(userDocRef, {
          orders: arrayUnion({
            orderId: orderId,
            ...orderList,
          }),
          cart: [],
        });
      }

      // Clear the cart items
      dispatch(cartActions.clearItem());

      // Send order confirmation email
      const emailJSPublicKey = "3d3Gcqrms-gLyNgPX";
      const emailJSServiceId = "service_6larayj";
      const emailJSTemplateId = "template_d1w24fh";

      // Compose the email parameters
      const emailParams = {
        from_name: "Star Furniture",
        to_name: "Star Furniture",
        my_html: `
        <div style="font-family: Arial, sans-serif; color: #333;">
          <h2 style="margin-bottom: 20px;">New Order!</h2>
      
          <div style="background-color: #f6f6f6; padding: 20px; border-radius: 6px;">
            <h3 style="margin-bottom: 10px;">Order Details:</h3>
            <p style="margin-bottom: 5px;"><strong>Name:</strong> ${
              formData.name
            }</p>
            <p style="margin-bottom: 5px;"><strong>Email:</strong> ${
              formData.email
            }</p>
            <p style="margin-bottom: 5px;"><strong>Phone Number:</strong> ${
              formData.phoneNumber
            }</p>
            <p style="margin-bottom: 5px;"><strong>Street Address:</strong> ${
              formData.streetAddress
            }</p>
            <p style="margin-bottom: 5px;"><strong>City:</strong> ${
              formData.city
            }</p>
            <p style="margin-bottom: 5px;"><strong>Postal Code:</strong> ${
              formData.postalCode
            }</p>
            <p style="margin-bottom: 5px;"><strong>Country:</strong> ${
              formData.country
            }</p>
          </div>
      
          <div style="margin-top: 20px;">
            <h3 style="margin-bottom: 10px;">Product Details:</h3>
            <table style="width: 100%; border-collapse: collapse;">
              <thead>
                <tr>
                  <th style="background-color: #f6f6f6; padding: 10px; border: 1px solid #ddd;">Image</th>
                  <th style="background-color: #f6f6f6; padding: 10px; border: 1px solid #ddd;">Product</th>
                  <th style="background-color: #f6f6f6; padding: 10px; border: 1px solid #ddd;">Quantity</th>
                  <th style="background-color: #f6f6f6; padding: 10px; border: 1px solid #ddd;">Price</th>
                </tr>
              </thead>
              <tbody>
                ${cartItems
                  .map(
                    (item, index) => `
                      <tr style="background-color: ${
                        index % 2 === 0 ? "#ffffff" : "#f6f6f6"
                      };">
                        <td style="padding: 10px; border: 1px solid #ddd;"><img src="${
                          item.imgUrl
                        }" alt="${
                      item.productName
                    }" style="width: 50px; height: 50px;"></td>
                        <td style="padding: 10px; border: 1px solid #ddd;">${
                          item.productName
                        }</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">${
                          item.quantity
                        }</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">$${
                          item.price
                        }</td>
                      </tr>
                    `
                  )
                  .join("")}
              </tbody>
            </table>
          </div>
      
          <div style="margin-top: 20px; background-color: #f6f6f6; padding: 20px; border-radius: 6px;">
            <p style="margin-bottom: 5px;"><strong>Total Quantity:</strong> ${totalQty}</p>
            <p style="margin-bottom: 5px;"><strong>Total Amount:</strong> $${totalAmount}</p>
          </div>
      
          <p style="margin-top: 20px;">Thank you!</p>
        </div>
      `,
      };

      // Send the email
      await emailjs.send(
        emailJSServiceId,
        emailJSTemplateId,
        emailParams,
        emailJSPublicKey
      );

      // Show success toast
      toast.success("Order placed successfully!  We will contact you soon.");

      // Navigate to the home page
      navigate("/home");
    } catch (error) {
      console.error("Error placing the order:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Helmet title="Checkout">
      <CommonSection title="Checkout" />

      <section>
        <Container>
          <Row>
            <Col lg="8">
              <h6 className="mb-4 fw-bold">Billing Information</h6>

              <Form className="billing__form" innerRef={ref}>
                <FormGroup className="form__group">
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="text"
                    name="streetAddress"
                    placeholder="Street Address"
                    value={formData.streetAddress}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                    value={formData.postalCode}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>

                <FormGroup className="form__group">
                  <input
                    type="text"
                    name="country"
                    placeholder="Country"
                    value={formData.country}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Form>
            </Col>
            <Col lg="4">
              <div className="checkout__cart">
                <h6>
                  Total Qty: <span>{totalItems} items</span>
                </h6>
                <h6>
                  Subtotal: <span>${totalPrice}</span>
                </h6>
                <h6>
                  <span>
                    Delivery Available: <br />
                    Cost varies on location
                  </span>
                </h6>

                <h4>
                  Total Cost: <span>${totalPrice}</span>
                </h4>
                {loading ? (
                  <div className="spinner">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="buy__btn auth__btn w-100"
                    onClick={handleSubmit}
                  >
                    Place an Order
                  </button>
                )}
              </div>
              {success &&
                "Your message has been sent. We'll get back to you soon."}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Checkout;
