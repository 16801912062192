import React, { useRef } from "react";
import { Container, Row } from "reactstrap";

import useAuth from "../custom-hooks/useAuth";
import "../styles/admin/admin-nav.css";

import { NavLink } from "react-router-dom";

const admin__nav = [
  {
    display: "Dashboard",
    path: "/dashboard",
  },
  {
    display: "Products",
    path: "/dashboard/all-products",
  },
  {
    display: "Orders",
    path: "/dashboard/orders",
  },
  {
    display: "Users",
    path: "/dashboard/users",
  },
];

const AdminNav = () => {
  const { currentUser } = useAuth();

  const menuRef = useRef(null);

  const menuToggle = () => menuRef.current.classList.toggle("active__menu");

  return (
    <>
      <header className="admin__header">
        <div className="admin__nav-top">
          <Container>
            <div className="dashboard__menu">
              <span onClick={menuToggle}>
                <i className="ri-menu-line"></i>
              </span>
            </div>
            <div className="admin__nav-wrapper-top">
              <div
                className="admin__navigation"
                ref={menuRef}
                onClick={menuToggle}
              >
                <ul className="admin__menu-list">
                  {admin__nav.map((item, index) => (
                    <li className="admin__menu-item" key={index}>
                      <NavLink
                        to={item.path}
                        className={(navClass) =>
                          navClass.isActive ? "active___admin-menu" : ""
                        }
                      >
                        {item.display}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </Container>
        </div>
      </header>
    </>
  );
};

export default AdminNav;
