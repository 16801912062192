import React from "react";
import { motion } from "framer-motion";
import "../../styles/product-card.css";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { useDispatch } from "react-redux";
import { cartActions } from "../../redux/slices/cartSlice";

const ProductCard = ({ item }) => {
  const dispatch = useDispatch();

  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id: item.id,
        productName: item.productName,
        price: item.price,
        imgUrl:
          item.images && item.images.length > 0 ? item.images[0].imgUrl : "",
      })
    );

    toast.success("Product added Successfully");
  };

  return (
    <Col lg="3" md="4" className=" mb-2">
      <div className="product__container">
        <div className="product__item product__placeholder">
          <div className="product__img ">
            {item.images && item.images.length > 0 ? (
              <motion.img
                whileHover={{ scale: 0.9 }}
                src={item.images[0].imgUrl} // Displaying the first image from the array
                alt=""
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <img src={"default-image-url"} alt="" /> // Replace with default image URL
            )}
          </div>

          <div className=" product__info">
            <h3 className="product__name ">
              <Link to={`/shop/${item.id}`}>
                {item.productName}{" "}
                <span className="product__rating">
                  ({item.avgrating ? item.avgrating : "0.0"})
                </span>
              </Link>
              <p className="product__category">{item.category}</p>
            </h3>
          </div>

          <div className="product__card-buttom d-flex align-items-center justify-content-between p-2 ">
            <span className="price">${item.price}</span>

            <motion.span whileTap={{ scale: 1.2 }} onClick={addToCart}>
              <i class="ri-add-line"></i>
            </motion.span>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProductCard;
