import React, { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import useGetData from "../custom-hooks/useGetData";

import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../firebase.config";
import { toast } from "react-toastify";

import Modal from "react-modal";

import "../styles/admin/users.css";

const Users = () => {
  const { data: usersData, loading } = useGetData("users");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedOrderId, setExpandedOrderId] = useState(null);

  const handleShowDetails = (id) => {
    setSelectedUserId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedUserId(null);
    setIsModalOpen(false);
  };

  const deleteUser = async (id) => {
    await deleteDoc(doc(db, "users", id));
    toast.success("User Deleted");
  };

  const toggleOrderDetails = (orderId) => {
    setExpandedOrderId((prevOrderId) =>
      prevOrderId === orderId ? null : orderId
    );
  };

  return (
    <section className="pt-3">
      <Container>
        <Row>
          <Col lg="12">
            <h4 className="fw-bold">Users</h4>
          </Col>
          <Col lg="12 " className="pt-3">
            <table className="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <h3 className="pt-5 fw-bold">Loading...</h3>
                ) : (
                  usersData?.map((user) => (
                    <tr key={user.id}>
                      <td>
                        <img src={user.photoURL} alt="" />
                      </td>
                      <td>{user.displayName}</td>
                      <td>{user.email}</td>
                      <td className="text-start">
                        <button
                          className="btn btn-secondary"
                          onClick={() => handleShowDetails(user.id)}
                        >
                          Details
                        </button>
                        {/* <button
                          className="btn btn-danger"
                          onClick={() => {
                            deleteUser(user.uid);
                          }}
                        >
                          <i class="ri-delete-bin-line"></i>
                        </button> */}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="User Details"
          ariaHideApp={false}
        >
          {selectedUserId && (
            <div className="user__details">
              <h2 className="fw-bold ">User Details</h2>
              <h3 className="fs-4">Cart Items</h3>
              <table className="table product-table">
                <thead>
                  <tr className="text-center cart__color">
                    <th>Image</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {usersData.map((user) => {
                    if (user.id === selectedUserId && user.cart) {
                      return (
                        user.cart.length > 0 &&
                        user.cart.map((cart) => (
                          <tr key={cart.id} className="text-center">
                            <td>
                              <img src={cart.imgUrl} alt="" />
                            </td>
                            <td>{cart.productName}</td>
                            <td>{cart.quantity}</td>
                            <td>{cart.price}</td>
                          </tr>
                        ))
                      );
                    }
                    return null;
                  })}
                </tbody>

                <h3 className="mt-5 fw-bold fs-3">Orders</h3>
                <thead>
                  <tr className="text-center cart__color">
                    <th>Name</th>
                    <th>Order Id</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {/* <tbody>
                  {usersData.map((user) => {
                    if (user.id === selectedUserId && user.orders) {
                      return (
                        user.orders.length > 0 &&
                        user.orders.map((order) => (
                          <tr key={order.orderId} className="text-center">
                            <td>{order.name}</td>
                            <td>
                              {order.orderId}
                              <p>{order.timestamp}</p>
                            </td>
                            <td>{order.products.length}</td>
                            <td>{order.totalQty}</td>
                            <td>{order.totalAmount}</td>
                            <td className="fs-2 pt-0">
                              <i class="ri-arrow-drop-down-line"></i>
                            </td>
                          </tr>
                        ))
                      );
                    }
                    return null;
                  })}
                </tbody> */}

                <tbody>
                  {usersData.map((user) => {
                    if (user.id === selectedUserId && user.orders) {
                      return (
                        user.orders.length > 0 &&
                        user.orders.map((order) => (
                          <React.Fragment key={order.orderId}>
                            <tr className="text-center">
                              <td>{order.name}</td>
                              <td>
                                {order.orderId}
                                <p>{order.timestamp}</p>
                              </td>
                              <td>{order.products.length}</td>
                              <td>{order.totalQty}</td>
                              <td>${order.totalAmount}</td>
                              <td
                                className="fs-2 pt-0"
                                onClick={() =>
                                  toggleOrderDetails(order.orderId)
                                }
                              >
                                <i
                                  className={`ri-arrow-drop-${
                                    expandedOrderId === order.orderId
                                      ? "up"
                                      : "down"
                                  }-line`}
                                ></i>
                              </td>
                            </tr>
                            {expandedOrderId === order.orderId && (
                              <>
                                <tr className="order-details-row">
                                  <td colSpan="6">
                                    <h3>
                                      Order Details <p>ID: {order.orderId}</p>{" "}
                                      <p>Name: {order.name}</p>
                                    </h3>
                                    <div className="users-billing-info">
                                      <h3>Billing Information</h3>
                                      <table>
                                        <tbody className="table table-bordered users-billing-table">
                                          <tr>
                                            <td>
                                              <strong>Name:</strong>
                                            </td>
                                            <td>{order.name}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Email:</strong>
                                            </td>
                                            <td>{order.email}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Phone Number:</strong>
                                            </td>
                                            <td>{order.phoneNumber}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Street Address:</strong>
                                            </td>
                                            <td>{order.streetAddress}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>City:</strong>
                                            </td>
                                            <td>{order.city}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Postal Code:</strong>
                                            </td>
                                            <td>{order.postalCode}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Country:</strong>
                                            </td>
                                            <td>{order.country}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <strong>Total Amount:</strong>
                                            </td>
                                            <td>${order.totalAmount}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>

                                    {order.products.length > 0 && (
                                      <div className="order-details">
                                        <table>
                                          <thead>
                                            <tr>
                                              <th>Image</th>
                                              <th>Product</th>
                                              <th>Quantity</th>
                                              <th>Price</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {order.products.map((product) => (
                                              <tr key={product.productId}>
                                                <td>
                                                  <img
                                                    src={product.imgUrl}
                                                    alt=""
                                                  />
                                                </td>
                                                <td>{product.productName}</td>
                                                <td>{product.quantity}</td>
                                                <td>${product.price}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              </>
                            )}
                          </React.Fragment>
                        ))
                      );
                    }
                    return null;
                  })}
                </tbody>

                <h3 className="fs-4 mt-5">Reviews</h3>
                <thead>
                  <tr className="text-center cart__color">
                    <th>Image</th>
                    <th>Product</th>
                    <th>Rating</th>
                    <th>Review</th>
                    <th>Username</th>
                    <th>Time</th>
                  </tr>
                </thead>

                <tbody>
                  {usersData.map((user) => {
                    if (user.id === selectedUserId && user.reviews) {
                      return (
                        user.reviews.length > 0 &&
                        user.reviews.map((review) => (
                          <tr key={review.id} className="text-center">
                            <td>
                              <img src={review.image.imgUrl} alt="" />
                            </td>
                            <td>{review.productName}</td>
                            <td>{review.rating}</td>
                            <td>{review.text}</td>
                            <td>{review.userName}</td>
                            <td>{review.timestamp}</td>
                          </tr>
                        ))
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
              <button className="btn btn-primary" onClick={closeModal}>
                Close
              </button>
            </div>
          )}
        </Modal>
      </Container>
    </section>
  );
};

export default Users;
