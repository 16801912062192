import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { db } from "../firebase.config";
import { doc, deleteDoc, updateDoc } from "firebase/firestore";
import useGetData from "../custom-hooks/useGetData";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import "../styles/admin/allproducts.css";
// Modal.setAppElement("#root");

const AllProducts = () => {
  const { data: productsData, loading } = useGetData("products");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedProduct, setEditedProduct] = useState({});
  const [editedTitle, setEditedTitle] = useState("");
  const [editedShortDesc, setEditedShortDesc] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedCategory, setEditedCategory] = useState("");
  const [editedPrice, setEditedPrice] = useState("");
  const [sectionCategory, setSectionCategory] = useState("");

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const deleteProduct = async (id) => {
    const confirmation = window.confirm("Do you want to delete the product?");

    if (confirmation) {
      await deleteDoc(doc(db, "products", id));
      toast.success("Deleted!");
    }
  };

  const saveProduct = async () => {
    try {
      const productRef = doc(db, "products", editedProduct.id);
      await updateDoc(productRef, {
        productName: editedTitle,
        shortDesc: editedShortDesc,
        description: editedDescription,
        category: editedCategory,
        price: editedPrice,
        section: sectionCategory,
      });
      toast.success("Product successfully updated");
      toggleModal();
    } catch (error) {
      toast.error("Error updating product");
    }
  };

  const handleEdit = (product) => {
    setEditedProduct(product);
    setEditedTitle(product.productName);
    setEditedShortDesc(product.shortDesc);
    setEditedDescription(product.description);
    setEditedCategory(product.category);
    setEditedPrice(product.price);
    setSectionCategory(product.section);
    toggleModal();
  };

  return (
    <section className="pt-3">
      <Container>
        <Row>
          <Col lg="12">
            <Row className="mb-3 ms-0">
              <Col lg="11" className="d-flex justify-content-between ">
                <h4 className="fw-bold">All Products</h4>

                <Link to="/dashboard/add-product" className="btn btn-primary">
                  Add +
                </Link>
              </Col>
            </Row>
            <table className="table">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Title</th>
                  <th className="text-center">Category</th>
                  <th className="text-center">Price</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Delete</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      Loading...
                    </td>
                  </tr>
                ) : (
                  productsData.map((item) => (
                    <tr key={item.id}>
                      <td>
                        {item.images && item.images.length > 0 && (
                          <img src={item.images[0].imgUrl} alt="" />
                        )}
                      </td>
                      <td>
                        {item.productName} <p>Id: {item.id}</p>
                      </td>
                      <td className="text-center">{item.category}</td>
                      <td className="text-center">${item.price}</td>
                      <td className="text-center px-0">
                        <button
                          className="btn btn-secondary mx-2"
                          onClick={() => handleEdit(item)}
                        >
                          <i class="ri-edit-2-line"></i>
                        </button>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-danger"
                          onClick={() => deleteProduct(item.id)}
                        >
                          <i class="ri-delete-bin-line"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            <Modal isOpen={isModalOpen} toggle={toggleModal}>
              <div className="fw-bold" toggle={toggleModal}>
                Edit Product
              </div>
              <div>
                <form>
                  <div className="mb-3">
                    <label htmlFor="editTitle" className="form-label">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="editTitle"
                      value={editedTitle}
                      onChange={(e) => setEditedTitle(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="editShortDesc" className="form-label">
                      Short Description
                    </label>
                    <textarea
                      className="form-control"
                      id="editShortDesc"
                      rows="3"
                      value={editedShortDesc}
                      onChange={(e) => setEditedShortDesc(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="editDescription" className="form-label">
                      Description
                    </label>
                    <textarea
                      className="form-control"
                      id="editDescription"
                      rows="5"
                      value={editedDescription}
                      onChange={(e) => setEditedDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="editCategory" className="form-label">
                      Category
                    </label>
                    <select
                      className="form-control"
                      id="editCategory"
                      value={editedCategory}
                      onChange={(e) => setEditedCategory(e.target.value)}
                    >
                      <option>Select Category</option>
                      <option value="dining">Dining Room</option>
                      <option value="sofa">Sofa</option>
                      <option value="bed">Bed</option>
                      <option value="mattress">Mattress</option>
                      <option value="coffee_table">Coffee Table</option>
                      <option value="carpet">Carpet</option>
                      <option value="pillows">Pillows</option>
                      <option value="stool">Bar Stool</option>
                      <option value="sofa_bed">Sofa Bed</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="sectionCategory" className="form-label">
                      Section
                    </label>
                    <select
                      className="form-control"
                      id="sectionCategory"
                      value={sectionCategory}
                      onChange={(e) => setSectionCategory(e.target.value)}
                    >
                      <option>Select Section</option>

                      <option value="trending">Trending Products</option>
                      <option value="best_sales">Best Sales</option>
                      <option value="new">New Arrivals</option>
                      <option value="popular">Popular Category</option>
                    </select>
                  </div>

                  <div className="mb-3">
                    <label htmlFor="editPrice" className="form-label">
                      Price
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="editPrice"
                      value={editedPrice}
                      onChange={(e) => setEditedPrice(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <div>
                <Button color="primary" onClick={saveProduct}>
                  Save
                </Button>{" "}
                <Button color="secondary" onClick={toggleModal}>
                  Cancel
                </Button>
              </div>
            </Modal>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AllProducts;
