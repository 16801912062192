import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import Shop from "../pages/Shop";
import Cart from "../pages/Cart";
import ProductDetails from "../pages/ProductDetails";
import Checkout from "../pages/Checkout";
import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ProtectedRoute from "./ProtectedRoute";

import AddProduct from "../admin/AddProduct";
import AllProducts from "../admin/AllProducts";
import Dashboard from "../admin/Dashboard";
import Users from "../admin/Users";
import LoginPage from "../admin/LoginPage";
import Forgot from "../pages/Forgot";
import Information from "../pages/profile/Information";
import MyOrders from "../pages/profile/MyOrders";

import MyReviews from "../pages/profile/MyReviews";

import AddressBook from "../pages/profile/AddressBook";
import Wishlist from "../pages/profile/Wishlist";
import ChangePassword from "../pages/profile/ChangePassword";
import Orders from "../admin/Orders";

import { useSelector } from "react-redux";
import NotFoundPage from "../pages/NotFoundPage";

import BedroomCategory from "../pages/BedroomCategory";
import SofaCategory from "../pages/SofaCategory";
import DiningCategory from "../pages/Diningcategory";

const Routers = () => {
  const { userRole } = useSelector((state) => state.user);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} />
      <Route path="home" element={<Home />} />
      <Route path="shop" element={<Shop />} />
      <Route path="shop/:id" element={<ProductDetails />} />
      <Route path="cart" element={<Cart />} />
      <Route path="shop/bedroom-mattress" element={<BedroomCategory />} />
      <Route path="shop/lounges-sofabed" element={<SofaCategory />} />
      <Route path="shop/dining-barstool" element={<DiningCategory />} />

      <Route path="/*" element={<ProtectedRoute />}>
        <Route path="checkout" element={<Checkout />} />

        {userRole === "admin" ? (
          <>
            {/* <Route path="admin" element={<Dashboard />} /> */}
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="dashboard/all-products" element={<AllProducts />} />
            <Route path="dashboard/add-product" element={<AddProduct />} />
            <Route path="dashboard/users" element={<Users />} />
            <Route path="dashboard/orders" element={<Orders />} />
          </>
        ) : (
          <Route to="/home" />
        )}

        <Route path="profile/information" element={<Information />} />
        <Route path="profile/address-book" element={<AddressBook />} />
        <Route path="profile/my-orders" element={<MyOrders />} />
        <Route path="profile/wishlist" element={<Wishlist />} />
        <Route path="profile/my-reviews" element={<MyReviews />} />
        <Route path="profile/change-password" element={<ChangePassword />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="login" element={<Login />} />
      <Route path="signup" element={<Signup />} />
      <Route path="forgot-password" element={<Forgot />} />
    </Routes>
  );
};

export default Routers;
