import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import { motion } from "framer-motion";

import Helmet from "../components/Helmet/Helmet";
import "../styles/home.css";

import { Container, Row, Col, Button } from "reactstrap";
import heroImg from "../assets/images/hero-img.png";

import Services from "../services/Services";
import ProductsList from "../components/UI/ProductsList";

import Clock from "../components/UI/Clock";

import useGetData from "../custom-hooks/useGetData";
import hero from "../assets/images/hero.jpg";

const Home = () => {
  const { data: products, loading } = useGetData("products");

  const [trendingProducts, setTrendingProducts] = useState([]);
  const [bestSalesProducts, setBestSalesProducts] = useState([]);
  const [bedProducts, setBedProducts] = useState([]);
  const [wirelessProducts, setWirelessProducts] = useState([]);
  const [popularProducts, setPopularProducts] = useState([]);

  const year = new Date().getFullYear();

  useEffect(() => {
    const filteredTrendingProducts = products.filter(
      (item) => item.section === "trending" || item.category === "sofa"
    );

    const filteredBestSalesProducts = products.filter(
      (item) => item.category === "sofa" || item.section === "best_sales"
    );

    const filteredBedProducts = products.filter(
      (item) => item.category === "bed"
    );

    const filteredWirelessProducts = products.filter(
      (item) => item.category === "coffee_table"
    );

    const filteredPopularProducts = products.filter(
      (item) => item.category === "coffee_table" || item.section === "popular"
    );

    setTrendingProducts(filteredTrendingProducts);
    setBestSalesProducts(filteredBestSalesProducts);
    setBedProducts(filteredBedProducts);
    setWirelessProducts(filteredWirelessProducts);
    setPopularProducts(filteredPopularProducts);
  }, [products]);

  return (
    <Helmet title={"Home"}>
      <section className="hero__section">
        <Container>
          <Row className="d-flex align-center justify-content-center">
            <Col lg="6" md="6">
              <div className="hero__content mt-5">
                <h2>Make Your Interior More Minimalistic & Modern</h2>

                <motion.button
                  whileTap={{ scale: 1.2 }}
                  className=" buy__btn mt-0 "
                >
                  <Link to="/shop">SHOP NOW</Link>
                </motion.button>
              </div>
            </Col>

            {/* <Col lg="6" md="6">
              <div className="hero__img">
                <img src={heroImg} alt="" />
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>

      <Services />

      <section className="trending__products py-0">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title mb-5">Trending</h2>
            </Col>

            {loading ? (
              <div className="product__item product__placeholder">
                {/* Placeholder content for loading effect */}
                <div className="product__img"></div>
                <div className="p-2 product__info">
                  <div className="product__name-placeholder"></div>
                  <div className="product__category-placeholder"></div>
                </div>
                <div className="product__card-buttom-placeholder"></div>
              </div>
            ) : (
              <>
                {" "}
                <ProductsList data={trendingProducts} />
              </>
            )}
          </Row>
          {/* <Row className="d-flex justify-content-center">
            <button className="btn btn-secondary w-25">
              All Products
            </button>
          </Row> */}
        </Container>
      </section>

      <section className="best__sales pt-0 mt-5">
        <Container>
          <Row>
            <Col lg="12" className="text-center">
              <h2 className="section__title mb-5">Best Sales</h2>
            </Col>
            {loading ? (
              <div className="product__item product__placeholder">
                {/* Placeholder content for loading effect */}
                <div className="product__img"></div>
                <div className="p-2 product__info">
                  <div className="product__name-placeholder"></div>
                  <div className="product__category-placeholder"></div>
                </div>
                <div className="product__card-buttom-placeholder"></div>
              </div>
            ) : (
              <ProductsList data={bestSalesProducts} />
            )}
          </Row>
        </Container>
      </section>

      <section
        className="timer__count"
        style={{
          backgroundImage:
            'url("https://cdn.pixabay.com/photo/2020/02/19/10/46/bedroom-4861910_1280.jpg")',
          backgroundPosition: "center bottom",
        }}
      >
        <Container>
          <Row>
            <Col className="text-center">
              <h3 className="pt-5">Decorate your home with us</h3>
              <div>
                <Link to="/shop">
                  <button className="btn btn-primary">Shop Now</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="new__arrivals">
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h2 className="section__title">New Arrivals</h2>
            </Col>
            {loading ? (
              <div className="product__item product__placeholder">
                <div className="product__img"></div>
                <div className="p-2 product__info">
                  <div className="product__name-placeholder"></div>
                  <div className="product__category-placeholder"></div>
                </div>
                <div className="product__card-buttom-placeholder"></div>
              </div>
            ) : (
              <ProductsList data={bedProducts} />
            )}
          </Row>
        </Container>
      </section> */}

      <section className="popular__category pt-3 mt-4">
        <Container>
          <Row>
            <Col lg="12" className="text-center mb-5">
              <h2 className="section__title">Popular in Category</h2>
            </Col>
            {loading ? (
              <div className="product__item product__placeholder">
                {/* Placeholder content for loading effect */}
                <div className="product__img"></div>
                <div className="p-2 product__info">
                  <div className="product__name-placeholder"></div>
                  <div className="product__category-placeholder"></div>
                </div>
                <div className="product__card-buttom-placeholder"></div>
              </div>
            ) : (
              <ProductsList data={popularProducts} />
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Home;
