import React, { useEffect, useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { auth } from "../../firebase.config";
import { getAuth, updatePassword } from "firebase/auth";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { useLocation } from "react-router-dom";

import "../../styles/profile/address.css";

const ChangePassword = () => {
  const location = useLocation();

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [showCurrentPassword, setShowCurrentPassword] = useState(false); // Track whether to show or hide the password
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setError(""); // Reset the error message

    const user = auth.currentUser;

    if (!user) {
      setError("User not authenticated.");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setError("New password and confirm password do not match.");
      return;
    }

    updatePassword(user, newPassword)
      .then(() => {
        // Password successfully updated
        setPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setError("");
      })
      .catch((error) => {
        setError("Error updating password: " + error.message);
      });
  };

  // hide unhide password
  const toggleShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Helmet title="My Profile">
      <section>
        <Container>
          <Row>
            <Col lg="6">
              {location.pathname === "/profile/change-password" && (
                <>
                  <h3 className="address-content__title">Change Password</h3>

                  {error && <p>{error}</p>}
                  <div className="address__card">
                    <div className="address-card__content">
                      <>
                        <Form onSubmit={handleSubmit}>
                          <FormGroup className="form__group">
                            <span>Current Password</span>
                            <div className="password-input">
                              <input
                                type={showCurrentPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <button
                                type="button"
                                className="password-toggle-btn"
                                onClick={toggleShowCurrentPassword}
                              >
                                {showCurrentPassword ? (
                                  <i class="ri-eye-off-line"></i>
                                ) : (
                                  <i class="ri-eye-line"></i>
                                )}
                              </button>
                            </div>
                          </FormGroup>

                          <FormGroup className="form__group">
                            <span>New Password</span>
                            <div className="password-input">
                              <input
                                type={showNewPassword ? "text" : "password"}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                              />
                              <button
                                type="button"
                                className="password-toggle-btn"
                                onClick={toggleShowNewPassword}
                              >
                                {showNewPassword ? (
                                  <i class="ri-eye-off-line"></i>
                                ) : (
                                  <i class="ri-eye-line"></i>
                                )}
                              </button>
                            </div>
                          </FormGroup>

                          <FormGroup className="form__group">
                            <span>Confirm New Password</span>
                            <div className="password-input">
                              <input
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmNewPassword}
                                onChange={(e) =>
                                  setConfirmNewPassword(e.target.value)
                                }
                              />
                              <button
                                type="button"
                                className="password-toggle-btn"
                                onClick={toggleShowConfirmPassword}
                              >
                                {showConfirmPassword ? (
                                  <i class="ri-eye-off-line"></i>
                                ) : (
                                  <i class="ri-eye-line"></i>
                                )}
                              </button>
                            </div>
                          </FormGroup>

                          <button
                            className="btn btn-primary address-card__save-btn"
                            type="submit"
                          >
                            Change
                          </button>
                        </Form>
                      </>
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default ChangePassword;
