import React, { useState, useRef, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";

import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { toast } from "react-toastify";

import "../styles/product-details.css";
import { motion } from "framer-motion";
import ProductsList from "../components/UI/ProductsList";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../redux/slices/cartSlice";

import { db, auth } from "../firebase.config";
import { doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import useGetData from "../custom-hooks/useGetData";

const ProductDetails = () => {
  const [product, setProduct] = useState({
    reviews: [],
    avgRating: 0,
    images: [],
  });

  const [tab, setTab] = useState("desc");
  const reviewUser = useRef("");
  const reviewMsg = useRef("");
  const dispatch = useDispatch();
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (product.images && product.images.length > 0) {
      setSelectedImage(product.images[0]);
    }
  }, [product.images]);

  const [rating, setRating] = useState(null);
  const { id } = useParams();

  const { data: products } = useGetData("products");

  const docRef = doc(db, "products", id);

  // const userId = useSelector((state) => state.user.userId);
  const { userId } = useSelector((state) => state.user);

  useEffect(() => {
    // Check if userId is available
    const getProduct = async () => {
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const productData = docSnap.data();
        setProduct(productData);

        // Calculate the average rating
        const { reviews } = productData;
        if (reviews && reviews.length > 0) {
          const totalRating = reviews.reduce(
            (sum, review) => sum + review.rating,
            0
          );
          const averageRating = totalRating / reviews.length;
          setProduct((prevProduct) => ({
            ...prevProduct,
            avgRating: averageRating.toFixed(1), // Round to 1 decimal place
          }));

          await updateDoc(docRef, {
            avgrating: averageRating.toFixed(1),
          });
        } else {
          setProduct((prevProduct) => ({
            ...prevProduct,
            avgRating: 0, // Set average rating to 0 if there are no reviews
          }));
        }

        // Fetch and update the display name for each review
        const reviewsWithDisplayName = await Promise.all(
          reviews.map(async (review) => {
            const userDocRef = doc(db, "users", review.userId);
            const userDocSnap = await getDoc(userDocRef);
            if (userDocSnap.exists()) {
              const userData = userDocSnap.data();
              return {
                ...review,
                displayName: userData.displayName,
              };
            } else {
              return review;
            }
          })
        );

        setProduct((prevProduct) => ({
          ...prevProduct,
          reviews: reviewsWithDisplayName || [],
        }));

        setShouldScrollToTop(true); // Scroll to top when the product changes
      } else {
        console.log("no product");
      }
    };

    getProduct();
  }, [id]);

  useEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setShouldScrollToTop(false);
    }
  }, [shouldScrollToTop]);

  const {
    imgUrl,
    productName,
    price,
    avgRating,
    reviews,
    description,
    shortDesc,
    category,
  } = product;

  //to show related products at the buttom of the page
  const currentItem = products.find((item) => item.id === id);
  const sameCategoryProducts = products.filter(
    (item) => item.category === currentItem.category && item.id !== id
  );

  let relatedProducts;
  if (sameCategoryProducts.length > 0) {
    relatedProducts = sameCategoryProducts.slice(0, 4);
  } else {
    const randomProducts = products.filter((item) => item.id !== id);
    relatedProducts = randomProducts.slice(0, 4);
  }

  const submitHandler = async (e) => {
    e.preventDefault();

    const reviewUserName = reviewUser.current.value;
    const reviewUserMsg = reviewMsg.current.value;

    if (!reviewUserName || !reviewUserMsg || !rating) {
      // Handle case where required fields are missing
      console.error("Required fields are missing.");
      return;
    }

    const reviewObj = {
      productId: id,
      userId: userId,
      productName: productName, // Add the current product name to the review object
      image: selectedImage, // Add the selected image to the review object
      userName: reviewUserName,
      text: reviewUserMsg,
      rating,
      timestamp: new Date().toLocaleString(),
    };

    try {
      // Save the review to Firestore
      const userDocRef = doc(db, "users", userId);

      // Fetch the user's document to check if the "reviews" field exists
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists() && !userDocSnap.data().reviews) {
        // If the "reviews" field doesn't exist, create it as an empty array
        await updateDoc(userDocRef, {
          reviews: [],
        });
      }

      // Update the reviews in the user's document
      await updateDoc(userDocRef, {
        reviews: arrayUnion(reviewObj),
      });

      // Update the reviews in the "products" collection for the specific product
      const productDocRef = doc(db, "products", id);
      await updateDoc(productDocRef, {
        reviews: arrayUnion(reviewObj),
      });

      // Update the reviews in the local state
      setProduct((prevProduct) => ({
        ...prevProduct,
        reviews: [...prevProduct.reviews, { id: docRef.id, ...reviewObj }],
      }));

      // Reset the form fields and rating
      reviewUser.current.value = "";
      reviewMsg.current.value = "";
      setRating(null);

      toast.success("Review Submitted");
    } catch (error) {
      console.error("Error saving review: ", error);
      toast.error("Failed to submit review");
    }
  };

  const addToCart = () => {
    dispatch(
      cartActions.addItem({
        id,
        image: imgUrl,
        productName,
        price,
      })
    );

    toast.success("Product added successfully");
  };

  const renderStarRating = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating - filledStars >= 0.5;

    const stars = [];
    for (let i = 0; i < filledStars; i++) {
      stars.push(<i key={i} className="ri-star-fill"></i>);
    }

    if (hasHalfStar) {
      stars.push(<i key="half" className="ri-star-half-fill"></i>);
    }

    return stars;
  };

  return (
    <Helmet title={productName}>
      <CommonSection title={productName} />

      <section className="pt-0">
        <Container>
          <Row>
            <Col lg="6">
              <div className="product__images">
                <div className="selected-image">
                  {selectedImage ? (
                    <img src={selectedImage.imgUrl} alt="" />
                  ) : (
                    <img src={"default-image-url"} alt="" /> // Replace with default image URL
                  )}
                </div>
                {product.images && product.images.length > 0 ? (
                  product.images.map((image, index) => (
                    <img
                      key={index}
                      src={image.imgUrl}
                      alt=""
                      className={selectedImage === image ? "selected" : ""}
                      onClick={() => setSelectedImage(image)}
                    />
                  ))
                ) : (
                  <img src={"default-image-url"} alt="" /> // Replace with default image URL
                )}
              </div>
            </Col>

            <Col lg="6">
              <div className="product__details">
                <h2>{productName}</h2>
                <div className="product__rating  d-flex align-items-center gap-3 mb-3">
                  <span>{renderStarRating(avgRating)}</span>
                  <p>
                    (<span>{avgRating}</span> ratings)
                  </p>
                </div>
                <div className="d-flex align-items-center gap-5 ">
                  <span className="product__price">${price}</span>
                  <span>Category: {category}</span>
                </div>
                <p className="mt-3" style={{ whiteSpace: "pre-line" }}>
                  {shortDesc}
                </p>

                <motion.button
                  whileTap={{ scale: 1.2 }}
                  className="buy__btn"
                  onClick={addToCart}
                >
                  Add to Cart
                </motion.button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="review__section">
        <Container>
          <Row>
            <Col lg="12">
              <div className="tab__wrapper d-flex align-items-center gap-5">
                <h6
                  className={`${tab === "desc" ? "active__tab" : ""}`}
                  onClick={() => setTab("desc")}
                >
                  Description
                </h6>
                <h6
                  className={`${tab === "rev" ? "active__tab" : ""}`}
                  onClick={() => setTab("rev")}
                >
                  Reviews
                  {reviews ? ` (${reviews.length})` : " (0)"}
                </h6>
              </div>

              {tab === "desc" ? (
                <div className="tab__content mt-5">
                  <p style={{ whiteSpace: "pre-line" }}>{description}</p>
                </div>
              ) : (
                <div className="product__review mt-5">
                  <div className="review__wrapper">
                    {reviews ? (
                      <ul>
                        {reviews.map((review, index) => (
                          <li key={index} className="review__item">
                            <div className="review__header">
                              <h5 className="review__username">
                                {review.userName}
                                <p>{review.displayName}</p>
                              </h5>
                              <p>{review.timestamp}</p>
                              <div className="review__rating">
                                {renderStarRating(review.rating)}
                              </div>
                            </div>
                            <p className="review__text">{review.text}</p>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>No reviews available</p>
                    )}
                    {userId ? (
                      <div className="review__form">
                        <h4>Leave your experience</h4>
                        <form action="" onSubmit={submitHandler}>
                          <div className="form__group">
                            <input
                              type="text"
                              placeholder="Enter Name"
                              ref={reviewUser}
                              required
                            />
                          </div>

                          <div className="form__group d-flex align-items-center gap-5 rating__group">
                            <motion.span
                              whileTap={{ scale: 1.2 }}
                              onClick={() => setRating(1)}
                              className={`rating__star${
                                rating === 1 ? " active" : ""
                              }`}
                            >
                              1<i class="ri-star-s-fill"></i>
                            </motion.span>
                            <motion.span
                              whileTap={{ scale: 1.2 }}
                              onClick={() => setRating(2)}
                              className={`rating__star${
                                rating === 2 ? " active" : ""
                              }`}
                            >
                              2<i class="ri-star-s-fill"></i>
                            </motion.span>
                            <motion.span
                              whileTap={{ scale: 1.2 }}
                              onClick={() => setRating(3)}
                              className={`rating__star${
                                rating === 3 ? " active" : ""
                              }`}
                            >
                              3<i class="ri-star-s-fill"></i>
                            </motion.span>
                            <motion.span
                              whileTap={{ scale: 1.2 }}
                              onClick={() => setRating(4)}
                              className={`rating__star${
                                rating === 4 ? " active" : ""
                              }`}
                            >
                              4<i class="ri-star-s-fill"></i>
                            </motion.span>
                            <motion.span
                              whileTap={{ scale: 1.2 }}
                              onClick={() => setRating(5)}
                              className={`rating__star${
                                rating === 5 ? " active" : ""
                              }`}
                            >
                              5<i class="ri-star-s-fill"></i>
                            </motion.span>
                          </div>

                          <div className="form__group">
                            <textarea
                              rows={4}
                              type="text"
                              placeholder="Review Message..."
                              ref={reviewMsg}
                              required
                            />
                          </div>

                          <motion.button
                            whileTap={{ scale: 1.2 }}
                            type="submit"
                            className="buy__btn mt-0"
                          >
                            Submit
                          </motion.button>
                        </form>
                      </div>
                    ) : (
                      <p>Please log in to leave a review</p>
                    )}
                  </div>
                </div>
              )}
            </Col>

            {relatedProducts.length > 0 && (
              <>
                <Col lg="12" className="mt-5">
                  <h2 className="related__title mb-4"> You might also like</h2>
                </Col>
                <ProductsList data={relatedProducts} />
              </>
            )}
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default ProductDetails;
