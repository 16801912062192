import React, { useState } from "react";
import Helmet from "../components/Helmet/Helmet";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { auth } from "../firebase.config";
import { sendPasswordResetEmail } from "firebase/auth";
import { toast } from "react-toastify";

import "../styles/forgot.css";

const Forgot = () => {
  const [email, setEmail] = useState("");

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent. Please check your inbox.");
    } catch (error) {
      toast.error("User doesn't exist.");
      console.log(error.message);
    }
  };

  return (
    <Helmet title="Forgot Password">
      <section>
        <Container>
          <Row>
            <Col lg="6" className="m-auto text-center">
              <h3 className="fw-bold mb-4">Forgot Password</h3>
              <Form className="auth__form" onSubmit={handleResetPassword}>
                <FormGroup className="form__group">
                  <div>
                    <p className="mt-0 mb-2">
                      Password reset link will be sent to your mail.
                    </p>
                  </div>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>

                <button type="submit" className="buy__btn auth__btn mt-0">
                  Request
                </button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Forgot;
