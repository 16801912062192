import React, { useEffect, useState } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { NavLink, useLocation, Link } from "react-router-dom";
import { auth, db } from "../../firebase.config";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

import "../../styles/profile/address.css";
import useAuth from "../../custom-hooks/useAuth";

const AddressBook = () => {
  const location = useLocation();

  const { currentUser } = useAuth();

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");

  // Handle changes in input fields
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // Handle changes in input fields
  const handleNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  // Handle changes in input fields
  const handleStreetChange = (e) => {
    setStreet(e.target.value);
  };

  // Handle changes in input fields
  const handleStateChange = (e) => {
    setState(e.target.value);
  };

  // Handle changes in input fields
  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const handlePostalCodeChange = (e) => {
    setPostalCode(e.target.value);
  };

  // Handle changes in input fields
  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const countries = ["Australia"];

  // Update user information in Firestore database
  const saveAddress = async (e) => {
    e.preventDefault();

    const userRef = doc(db, "users", currentUser.uid);

    // Create an object to store the updated user information
    const updatedUserData = {
      displayName: name,
      phoneNumber: phoneNumber,
      street: street,
      city: city,
      state: state,
      postalCode: postalCode,
      country: country,
    };

    try {
      await updateDoc(userRef, updatedUserData);
      toast.success("User information updated successfully");
    } catch (error) {
      toast.error("Error updating user information: ", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          const userData = userSnap.data();

          setName(userData.displayName || "");
          setPhoneNumber(userData.phoneNumber || "");
          setStreet(userData.street || "");
          setCity(userData.city || "");
          setState(userData.state || "");
          setPostalCode(userData.postalCode || "");
          setCountry(userData.country || "");
        }
      } catch (error) {
        console.error("Error fetching user information: ", error);
      }
    };

    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  return (
    <Helmet title="My Profile">
      <section>
        <Container>
          <Row>
            <Col lg="6">
              {location.pathname === "/profile/address-book" && (
                <>
                  <h3 className="address-content__title">ADDRESS BOOK</h3>

                  <div className="address__card">
                    <div className="address-card__content">
                      {currentUser && (
                        <>
                          <Form onSubmit={saveAddress}>
                            <FormGroup className="form__group">
                              <span>FULL NAME</span>
                              <input
                                type="text"
                                className="address-card__input"
                                value={name}
                                readOnly
                              />
                            </FormGroup>

                            <FormGroup className="form__group">
                              <span>MOBILE NUMBER</span>
                              <input
                                type="tel"
                                className="address-card__input"
                                value={phoneNumber}
                                readOnly
                              />
                            </FormGroup>

                            <FormGroup className="form__group">
                              <span>STREET ADDRESS</span>
                              <input
                                type="text"
                                className="address-card__input"
                                value={street}
                                onChange={handleStreetChange}
                              />
                            </FormGroup>

                            <FormGroup className="form__group">
                              <span>CITY</span>
                              <input
                                type="text"
                                className="address-card__input"
                                value={city}
                                onChange={handleCityChange}
                              />
                            </FormGroup>
                            <FormGroup className="form__group">
                              <span>STATE</span>
                              <input
                                type="text"
                                className="address-card__input"
                                value={state}
                                onChange={handleStateChange}
                              />
                            </FormGroup>

                            <FormGroup className="form__group">
                              <span>POSTAL CODE</span>
                              <input
                                type="text"
                                className="address-card__input"
                                value={postalCode}
                                onChange={handlePostalCodeChange}
                              />
                            </FormGroup>

                            <FormGroup className="form__group address-card_country">
                              <span>COUNTRY</span>
                              <select
                                className="address-card__dob-select"
                                value={country}
                                onChange={handleCountryChange}
                              >
                                <option value="">Country</option>
                                {countries.map((country, index) => (
                                  <option key={index} value={country}>
                                    {country}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>

                            <button
                              className="btn btn-primary address-card__save-btn"
                              type="submit"
                            >
                              Save
                            </button>
                          </Form>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default AddressBook;
