import React, { useState } from "react";
import "../styles/admin/orders.css";
import Modal from "react-modal";
import { db } from "../firebase.config";
import { addDoc, doc, setDoc, updateDoc } from "firebase/firestore";

import useGetData from "../custom-hooks/useGetData";

const Orders = () => {
  const { data: ordersData, setOrdersData, loading } = useGetData("orders");
  const { data: usersData, setUsersData } = useGetData("users");
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleStatusChange = async (orderId, newStatus) => {
    const orderRef = doc(db, "orders", orderId);
    try {
      // Update the status in Firestore
      await updateDoc(orderRef, {
        "orderList.status": newStatus,
      });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleShowDetails = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedOrderId(null);
    setIsModalOpen(false);
  };

  return (
    <section className="pt-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="row align-items-center justify-content-between ms-1">
              <div className="col-lg-6 ps-0 mb-4">
                <h4 className="fw-bold">All Orders</h4>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr className="">
                  <th>Order ID</th>
                  <th className="text-center">Customer</th>
                  <th className="text-center">Product</th>
                  <th className="text-center">Quantity</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td>Loading...</td>
                  </tr>
                ) : (
                  ordersData.map((order) => (
                    <tr key={order.id}>
                      <td>
                        {order.id}
                        <p>{order.orderList.timestamp}</p>
                      </td>
                      <td className="text-center">{order.orderList.name}</td>
                      <td className="text-center">
                        {order.orderList.products.length}
                      </td>
                      <td className="text-center">
                        {order.orderList.totalQty}
                      </td>
                      <td className="text-center">
                        <select
                          value={order.orderList.status}
                          onChange={(e) =>
                            handleStatusChange(order.id, e.target.value)
                          }
                        >
                          <option value="Processing">Processing</option>
                          <option value="Shipping">Shipping</option>

                          <option value="Shipped">Shipped</option>
                        </select>
                      </td>
                      <td className="text-center">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleShowDetails(order.id)}
                        >
                          Details
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Order Details"
      >
        {selectedOrderId && (
          <div className="order-details">
            <h2>Order Details</h2>
            <div className="product-info">
              <h3>Product Information</h3>
              <table className="table product-table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {ordersData.map((order) => {
                    if (order.id === selectedOrderId) {
                      return order.orderList.products.map((product) => (
                        <tr key={product.id}>
                          <td>
                            <img src={product.imgUrl} alt="" />
                          </td>
                          <td>{product.productName}</td>
                          <td>{product.quantity}</td>
                          <td>${product.price}</td>
                        </tr>
                      ));
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>
            <div className="billing-info">
              <h3>Billing Information</h3>
              <table className="table billing-table">
                <tbody>
                  {ordersData.map((order) => {
                    if (order.id === selectedOrderId) {
                      return (
                        <React.Fragment key={order.id}>
                          <p>Order Time: {order.orderList.timestamp}</p>
                          <tr>
                            <td>
                              <strong>Name:</strong>
                            </td>
                            <td>{order.orderList.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email:</strong>
                            </td>
                            <td>{order.orderList.email}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Phone Number:</strong>
                            </td>
                            <td>{order.orderList.phoneNumber}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Street Address:</strong>
                            </td>
                            <td>{order.orderList.streetAddress}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>City:</strong>
                            </td>
                            <td>{order.orderList.city}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Postal Code:</strong>
                            </td>
                            <td>{order.orderList.postalCode}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Country:</strong>
                            </td>
                            <td>{order.orderList.country}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Amount:</strong>
                            </td>
                            <td>${order.orderList.totalAmount}</td>
                          </tr>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </tbody>
              </table>
            </div>
            <button className="btn btn-primary" onClick={closeModal}>
              Close
            </button>
          </div>
        )}
      </Modal>
    </section>
  );
};

export default Orders;
