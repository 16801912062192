import React, { useState, useEffect } from "react";
import Helmet from "../../components/Helmet/Helmet";
import { Container, Row, Col, Form, FormGroup } from "reactstrap";
import { useLocation } from "react-router-dom";
import { auth, db, storage } from "../../firebase.config";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { ref, uploadString, getDownloadURL } from "firebase/storage";

import "../../styles/profile/information.css";
import useAuth from "../../custom-hooks/useAuth";
import { toast } from "react-toastify";

const Information = () => {
  const location = useLocation();
  const { currentUser } = useAuth();

  // State variables to store the input field values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  // Handle changes in input fields
  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleDayChange = (e) => {
    setDay(e.target.value);
  };

  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };

  const handleYearChange = (e) => {
    setYear(e.target.value);
  };

  // Generate options for day dropdown (1-31)
  const dayOptions = Array.from({ length: 31 }, (_, index) => index + 1);

  // Generate options for month dropdown (January-December)
  const monthOptions = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Generate options for year dropdown (1990-2023)
  const currentYear = new Date().getFullYear();
  const yearOptions = Array.from(
    { length: currentYear - 1990 + 1 },
    (_, index) => currentYear - index
  );

  // Update user profile image in Firebase Storage
  const updateUserProfileImage = async () => {
    if (selectedImage) {
      const storageRef = ref(
        storage,
        `profileImages/${currentUser.uid + "__" + currentUser.displayName}`
      );
      const uploadTask = uploadString(storageRef, selectedImage, "data_url");

      try {
        const snapshot = await uploadTask;
        const downloadURL = await getDownloadURL(snapshot.ref);

        // Update the photoURL in Firestore
        const userRef = doc(db, "users", currentUser.uid);
        const updatedUserData = {
          photoURL: downloadURL,
        };
        await updateDoc(userRef, updatedUserData);

        toast.success("Profile Image Changed");
      } catch (error) {
        console.error("Error uploading profile image: ", error);
      }
    }
  };

  // Handle image selection
  const handleImageSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Update user information in Firestore database
  const saveChanges = async (e) => {
    e.preventDefault();

    const userRef = doc(db, "users", currentUser.uid);

    // Create an object to store the updated user information
    const updatedUserData = {
      displayName: name,
      email: email,
      countryCode: countryCode,
      phoneNumber: phoneNumber,
      day: day,
      month: month,
      year: year,
    };

    try {
      await updateDoc(userRef, updatedUserData);
      toast.success("User information updated successfully");
    } catch (error) {
      console.error("Error updating user information: ", error);
    }
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (currentUser) {
          const userRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            const userData = userSnap.data();

            setName(userData.displayName || "");
            setEmail(userData.email || "");
            setCountryCode(userData.countryCode || "");
            setPhoneNumber(userData.phoneNumber || "");
            setDay(userData.day || "");
            setMonth(userData.month || "");
            setYear(userData.year || "");
            setSelectedImage(userData.photoURL || null); // Update selectedImage with photoURL
          }
        }
      } catch (error) {
        console.error("Error fetching user information: ", error);
      }
    };

    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  return (
    <Helmet title="My Profile">
      <section>
        <Container>
          <Row>
            <Col lg="6">
              {location.pathname === "/profile/information" && (
                <>
                  <h3 className="info-content__title">INFORMATION</h3>

                  <div className="info-card">
                    <div className="info-card__content">
                      {currentUser && (
                        <>
                          <Form onSubmit={saveChanges}>
                            <FormGroup className="info-card__item">
                              <span className="info-card__label">NAME</span>
                              <input
                                type="text"
                                className="info-card__input"
                                value={name}
                                onChange={handleNameChange}
                              />
                            </FormGroup>
                            <FormGroup className="info-card__item">
                              <span className="info-card__label">EMAIL</span>
                              <input
                                type="text"
                                className="info-card__input"
                                value={email}
                                onChange={handleEmailChange}
                                readOnly
                              />
                            </FormGroup>
                            <FormGroup className="info-card__item">
                              <span className="info-card__label">MOBILE</span>
                              <input
                                type="text"
                                className="info-card__input"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                              />
                            </FormGroup>
                            <FormGroup className="info-card__item">
                              <span className="info-card__label">
                                DATE OF BIRTH
                              </span>
                              <div className="info-card__dob">
                                <select
                                  className="info-card__dob-select"
                                  value={day}
                                  onChange={handleDayChange}
                                >
                                  <option value="">Day</option>
                                  {dayOptions.map((day) => (
                                    <option key={day} value={day}>
                                      {day}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className="info-card__dob-select"
                                  value={month}
                                  onChange={handleMonthChange}
                                >
                                  <option value="">Month</option>
                                  {monthOptions.map((month, index) => (
                                    <option key={index} value={month}>
                                      {month}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  className="info-card__dob-select"
                                  value={year}
                                  onChange={handleYearChange}
                                >
                                  <option value="">Year</option>
                                  {yearOptions.map((year) => (
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </FormGroup>
                            <button
                              className="btn btn-primary info-card__save-btn"
                              type="submit"
                            >
                              Save
                            </button>
                          </Form>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Col>

            <Col lg="4" className="mt-4 pt-4">
              {currentUser && (
                <>
                  <div className="info-card__image-container">
                    <img
                      className="info-card__image"
                      src={selectedImage || currentUser.photoURL}
                      alt=""
                    />
                    <label className="info-card__edit-btn">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageSelect}
                        style={{ display: "none" }}
                      />
                      Edit
                    </label>
                  </div>

                  <button
                    className="btn btn-primary info-card-img__change"
                    onClick={updateUserProfileImage}
                  >
                    Change
                  </button>
                </>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default Information;
