import { createSlice } from "@reduxjs/toolkit";

// Get userId and userRole from localStorage
const savedUserId = localStorage.getItem("userId");
const savedUserRole = localStorage.getItem("userRole");

const initialState = {
  userId: savedUserId ? savedUserId : null,
  displayName: null,
  userRole: savedUserRole ? savedUserRole : null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload.userId;
      state.displayName = action.payload.displayName;
      state.userRole = action.payload.userRole;
      // Save userId and userRole in localStorage
      localStorage.setItem("userId", action.payload.userId);
      localStorage.setItem("userRole", action.payload.userRole);
    },
  },
});

export const { setUserId } = userSlice.actions;
export default userSlice.reducer;
