const serviceData = [
  {
    icon: "ri-shopping-cart-line",
    title: "Shop Now",
    subtitle: "",
    bg: "#f2f6b2",
  },

  {
    icon: "ri-truck-line",
    title: "Delivery Available",
    subtitle: "",
    bg: "#fdefe6",
  },

  {
    icon: "ri-secure-payment-line",
    title: "Payment Options",
    subtitle: "Card and PayID",
    bg: "#e2f2b2",
  },
];

export default serviceData;
