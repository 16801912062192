import React, { useEffect, useState } from "react";
import "../styles/cart.css";
import Helmet from "../components/Helmet/Helmet";
import CommonSection from "../components/UI/CommonSection";
import { Container, Row, Col } from "reactstrap";

import { cartActions } from "../redux/slices/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { db } from "../firebase.config";
import { auth } from "../firebase.config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import useGetData from "../custom-hooks/useGetData";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Cart = () => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);

  const { data: usersData, loading } = useGetData("users");

  const [currentUserUID, setCurrentUserUID] = useState(null);

  // Retrieve the UID of the current logged-in user
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUserUID(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  // Get the current user's data
  const currentUserData = usersData?.find(
    (user) => user.uid === currentUserUID
  );

  useEffect(() => {
    if (currentUserData) {
      // Update the cart items in the Redux store
      dispatch(cartActions.setItems(currentUserData.cart));
    }
  }, [currentUserData]);

  const dispatch = useDispatch();

  // for total price from cart items
  const totalPrice = cartItems
    ? cartItems.reduce((sum, item) => {
        const itemPrice = parseFloat(item.price);
        const itemQuantity = parseFloat(item.quantity);
        return sum + itemPrice * itemQuantity;
      }, 0)
    : 0;

  const totalItems = cartItems
    ? cartItems.reduce((sum, item) => {
        const itemQuantity = parseFloat(item.quantity);
        return sum + itemQuantity;
      }, 0)
    : 0;

  const updateCartItems = async (userRef, items) => {
    try {
      // Update the 'cart' field in the user's document with the new items
      await updateDoc(userRef, { cart: items });
    } catch (error) {
      console.error("Error moving cart items", error);
    }
  };

  useEffect(() => {
    if (cartItems.length >= 0) {
      // Get the current user
      const user = getCurrentUser();

      // Check if the user is authenticated
      if (user) {
        // Get the user's Firestore document reference
        const userRef = doc(db, "users", user.uid);

        // Update the 'cart' field in the user's document with the new cart items
        updateCartItems(userRef, cartItems);
      }
    }
  }, [cartItems]);

  const getCurrentUser = () => {
    // Get the current authenticated user from Firebase Authentication
    return auth.currentUser;
  };

  useEffect(() => {
    // Update the total amount in local storage
    localStorage.setItem("totalAmount", totalAmount.toString());
  }, [totalAmount]);

  useEffect(() => {
    // Update the total quantity in local storage
    localStorage.setItem("totalQuantity", totalQuantity.toString());
  }, [totalQuantity]);

  const deleteProduct = async (productId) => {
    // Remove the item from the Redux store
    dispatch(cartActions.deleteItem(productId));

    try {
      // Get the current authenticated user from Firebase Authentication
      const user = getCurrentUser();

      if (user) {
        // Get the user's Firestore document reference
        const userRef = doc(db, "users", user.uid);

        // Get the current user's data
        const userSnapshot = await getDoc(userRef);
        const userData = userSnapshot.data();

        if (userData && userData.cart) {
          // Remove the item from the cart array
          const updatedCart = userData.cart.filter(
            (item) => item.id !== productId
          );

          // Update the 'cart' field in the user's document
          await updateDoc(userRef, { cart: updatedCart });
        }
      }
    } catch (error) {
      console.error("Error deleting product from the cart", error);
    }
  };

  //navigate to checkout
  const navigate = useNavigate();
  const handleCheckout = () => {
    if (cartItems.length === 0) {
      toast.error("Add items to the cart checking out");
    } else {
      navigate("/checkout");
    }
  };

  return (
    <Helmet title="Cart">
      <CommonSection title="Shopping Cart" />
      <section>
        <Container>
          <Row>
            <Col lg="9">
              {cartItems && cartItems.length === 0 ? (
                <h2 className="fs-4 text-center">No item added to the cart</h2>
              ) : (
                <table className="table bordered">
                  <thead>
                    <tr>
                      <th>Image</th>
                      <th>Title</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Delete</th>
                    </tr>
                  </thead>

                  <tbody>
                    {cartItems &&
                      cartItems.map((item) => (
                        <Tr
                          item={item}
                          key={item.id}
                          deleteProduct={deleteProduct}
                        />
                      ))}
                  </tbody>
                </table>
              )}
            </Col>

            <Col lg="3">
              <div className="cart-summary">
                <h6 className="d-flex align-items-center justify-content-between">
                  Subtotal
                  <span className="fs-4 fw-bold">${totalPrice}</span>
                </h6>
                <h6 className="d-flex align-items-center justify-content-between">
                  Total items
                  <span className="fs-4 fw-bold">{totalItems}</span>
                </h6>
              </div>
              <p className="fs-6 mt-2">
                Taxes and shipping will be calculated in checkout.
              </p>
              <div>
                <button
                  className="buy__btn w-100 fw-bold fs-6"
                  onClick={handleCheckout}
                >
                  Checkout
                </button>

                <button className="buy__btn w-100 mt-3 fw-bold fs-6">
                  <Link to="/shop">Continue Shopping</Link>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

const Tr = ({ item, deleteProduct }) => {
  return (
    <tr>
      <td>
        <img src={item.imgUrl} alt="" className="cart-item-image" />
      </td>
      <td>{item.productName}</td>
      <td>${item.price}</td>
      <td>{item.quantity}</td>
      <td className="">
        <i
          whileTap={{ scale: 1.2 }}
          onClick={() => deleteProduct(item.id)}
          className="ri-delete-bin-line text-danger fw-bold fs-5 btn"
        ></i>
      </td>
    </tr>
  );
};

export default Cart;
